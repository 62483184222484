import React from "react";
import {
  SubLabelHeader,
  ClaimWrapper,
  Wrapper,
  SubLabelWrapper,
  MobileWrapper,
  OuterWrapper,
} from "./HeroStyled";
import Doctolib from "./Doctolib";
// @ts-ignore
import Slogan from "../../images/slogan-2.png";
// @ts-ignore
import WhiteSlogan from "../../images/white-logo.png";

const Hero: React.FC = () => (
  <>
    <Doctolib />
    <Wrapper>
      <ClaimWrapper>
        <img className="slogan" src={Slogan} alt="slogan" />
        <SubLabelWrapper>
          <SubLabelHeader>
            Wege zu neuen Lösungen
            <br />
            bei Stress, Burnout, Depression, Angst
          </SubLabelHeader>
          <SubLabelHeader>Tinnitus-Therapie</SubLabelHeader>
        </SubLabelWrapper>
      </ClaimWrapper>
    </Wrapper>
    <OuterWrapper>
      <MobileWrapper>
        <img className="slogan" src={WhiteSlogan} alt="slogan" />
        <SubLabelWrapper>
          <SubLabelHeader>
            Wege zu neuen Lösungen <br />
            bei Stress, Burnout, Depression, Angst
          </SubLabelHeader>
          <SubLabelHeader>Tinnitus-Therapie</SubLabelHeader>
        </SubLabelWrapper>
      </MobileWrapper>
    </OuterWrapper>
  </>
);

export default Hero;
