import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Hero from "../components/Hero";
import { MENU_ITEMS } from "../components/Header/Header";
import { ThemeProvider } from "styled-components";
import theme from "../theme";

const IndexPage = () => {
  React.useEffect(() => {
    const el = document.getElementById(window.location.hash.replace('#', ''));
    if (!el) return;
    const headerOffset = 90;
    const elementPosition = el.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({ top: offsetPosition });
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Seo title="Auf Dich hören" />
        <Hero />
        {MENU_ITEMS.filter((t) => t.component).map(
          ({ id, component: Component }, i) => (
            <section key={i} id={id}>
              <Component />
            </section>
          )
        )}
      </Layout>
    </ThemeProvider>
  );
};

export default IndexPage;
