import React from 'react';

const Doctolib: React.FC = () => (
  <a
    href="https://www.doctolib.de/heilpraktiker-fur-psychotherapie/berlin/dirk-jacobs?utm_campaign=website-button&amp;utm_source=dirk-jacobs-website-button&amp;utm_medium=referral&amp;utm_content=option-8&amp;utm_term=dirk-jacobs"
    style={{
      position: "fixed",
      top: "180px",
      right: "0",
      display: "block",
      textAlign: "center",
      opacity: "0.85",
      backgroundColor: "#107ACA",
      zIndex: 10000,
      color: "#ffffff",
      fontSize: "14px",
      overflow: "hidden",
      width: "100px",
      borderRadius: "4px 0 0 4px",
      textDecoration: "none",
      padding: "10px",
      lineHeight: "1.4",
    }}
    rel="noopener noreferrer"
    target="_blank"
  >
    <span
      style={{
        fontSize: "14px",
      }}
    >
      Termin
      <br /> online buchen
    </span>
    <img
      style={{
        height: "15px",
        marginTop: "10px",
        verticalAlign: "middle",
        width: "auto",
      }}
      src="https://www.doctolib.de/external_button/doctolib-white-transparent.png"
      alt="Doctolib"
    />
  </a>
);

export default Doctolib;
