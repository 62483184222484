import styled from "styled-components";
// @ts-ignore
import image from "../../images/hero.jpeg";
import { HEADER_HEIGHT } from "../Header";

export const Wrapper = styled.section`
  background-image: ${`url(${image})`};
  height: ${`calc(100vh - ${HEADER_HEIGHT}px)`};
  background-size: cover;
  background-position: top right;

  @media (max-width: 630px) {
    background-position: center;
    height: ${`calc(50vh - ${HEADER_HEIGHT / 2}px)`};
  }
`;

export const SubLabelWrapper = styled.div`
  margin-top: 40px;
  margin-left: 3px;
  width: 100%;
`;

export const SubLabelHeader = styled.h3`
  color: ${({ theme }) => theme.primary};
  margin: 10px 0;
  font-size: 28px;
  font-weight: normal;
  line-height: 1.3;
`;

export const ClaimWrapper = styled.div`
  position: absolute;
  width: max-content;
  right: 60px;
  top: 220px;
  
  ${SubLabelHeader} {
    filter: brightness(85%);
  }

  img {
    width: 500px;
  }

  @media (max-width: 1300px) {
    img {
      width: 430px;
    }
    h3 {
      font-size: 22px;
    }
  }

  @media (max-width: 1169px) {
    right: 30px;
  }

  @media (max-width: 630px) {
    h3,
    p,
    img {
      display: none;
    }
  }
`;

export const OuterWrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.primary};
`;

export const MobileWrapper = styled.div`
  display: none;
  max-width: 400px;
  margin: 0 auto;
  padding: 30px 30px;

  h3,
  p {
    color: white;
    font-weight: 100;
  }

  h3 {
    font-size: 24px;
  }

  p {
    font-size: 16px;
  }

  @media (max-width: 630px) {
    display: block;
    min-height: ${`calc(50vh - ${HEADER_HEIGHT / 2}px)`};
    ${SubLabelWrapper} {
      margin-top: 25px;
    }
  }

  @media (max-width: 350px) {
    h3 {
      font-size: 20px;
    }
    p {
      font-size: 14px;
    }
  }
`;

export const Label = styled.div`
  line-height: 1.5;
  left: 170px;
  top: 320px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  color: white;
  text-align: initial;
  background: rgb(31, 161, 176, 0.7);
  padding: 10px;

  b {
    margin-bottom: -10px;
  }

  @media (max-width: 1270px) {
    left: 80px;
  }

  @media (max-width: 1150px) {
    left: 20px;
  }

  @media (max-width: 1100px) {
    top: 577px;
    left: unset;
    right: 250px;
  }

  @media (max-width: 630px) {
    top: calc(50vh - 70px);
    bottom: unset;
    right: 10px;
    font-size: 12px;
    left: unset;
  }
`;
